.form {
  display: block;
  margin: 0 auto;

  position: relative;
  text-align: center;
}

.input-widgets-container {
  text-align: center;
  width: 300px;
  margin: 0 auto;
}

.submit-button {
  border: 1px solid black;
  padding: 5px;
  margin-top: 20px;
}
/* .form label,
.form input,
.form textarea {

} */

/* 
.form label.checkbox,
.form input.checkbox {

  display: inline-block;
  width: auto;

  }

.form input.checkbox {
  margin-left: 20px;
}

.checkbox_container {
  margin-top: 30px;
  text-align: left;
}


.form input,
.form textarea {
  font: inherit;
  padding: 0.25rem;
  
  display: inline;
  width: 25%;
  border: 3px solid red;
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.actions button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  background-color: var(--color-gray-300);
  color: var(--color-gray-800);
  border: none;
}

.actions button[type='button'] {
  background-color: transparent;
  color: var(--color-gray-300);
}

.actions button:hover {
  background-color: var(--color-primary-300);
}

.actions button[type='button']:hover {
  background-color: var(--color-gray-800);
} */
