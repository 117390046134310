.header {
  max-width: 60rem;
  margin: auto;
  padding: 2rem;
  /* display: flex; */
  justify-content: space-between;
  text-align: center;
}

.header_content {
  display: block;
  margin: 0 auto;
}
.header_content_image {
  margin: 0 auto;
  display: block;
}

.header_content_navigation {
  display: inline-block;
  margin-top: 30px;
}

.list {
  display: flex;
  gap: 1rem;
}

.list a {
  text-decoration: none;
  color: #b86800;

  border: 1px solid transparent;
  padding: 5px;
  padding-top: 7px;
}

.list a:hover,
.list a.active {
  color: #b86800;
  /* text-decoration: underline; */
  border-color: #b86800;
  background-color: #fff5e7;
}
