div.input-widget {
  display: block;
  width: 300px;
}

div.input-widget > div.label-container {
  display: inline-block;
  text-align: left;
  width: 50%;
  vertical-align: top;
}

div.input-widget > div.input-container {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
